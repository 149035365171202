.bgImg {
    position: absolute;
    right: 0px;    
}

.qrCode-box {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-box {
    margin: 0 0 30px 0;
}

.text {
    margin-bottom: 3px;    
}

.userguide-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sth-product {
   display: flex;
   flex-direction: column; 
}

.sth-product-title {
    font-size: 20px;
    font-weight: bolder;
    /* padding: 15px 5px 0 5px; */
    padding: 5px 0;
}

.sth-product-content {
    /* padding: 0 10px; */
    padding: 1px 0;
}

.sth-tcTitle {
    font-size: 20px;
    font-weight: bolder;
    text-decoration: underline;
    padding: 25px 5px 8px 5px;
}

.sth-tcText-1 {
    font-size: 18px;
    margin: 10px 0;
}

.sth-tcText-2 {
    padding-left: 2px;
}

.branch {
    margin: 15px 30px;
}

.branchBox {
    display: flex;
    flex-direction: row;
}

.branchList-1 {
    width: 150px;
    display: flex;
    flex-direction: column; 
    border: 1px solid #000000;
    /* padding: 3px; */
    
}

.branchList-2 {
    width: 700px;
    display: flex;
    flex-direction: column; 
    border: 1px solid #000000;
    /* padding: 3px; */
}

.branchAddList {
    display: flex;
}

.items-1 {
    padding: 5px 0;
    text-align: center;
}

.items-2 {
    /* padding: 0 10px; */
    width: 25%;
    padding: 3px 5px;
    border: 1px solid #000000;
}

.items-3 {
    /* padding: 0 10px; */
    width: 75%;
    padding: 3px 5px;
    border: 1px solid #000000;
}