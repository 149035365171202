body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#toast-container-main {
  position: fixed;
  top: 20px;
  right: 35px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  width: 200px;
}

#toast-container-main > div {
  /* background-color: rgba(0, 0, 0, 0.15); */
  background-color: #666666;
  color: white;
  width: 100%;
  margin-bottom: 10px;
  min-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  animation: slide-in 0.2s;
  transition: all 0.3s ease;
}

.toast-header {
  display: flex;
  /* flex-direction: row;
  justify-content: space-between; */
  /* align-items: center; */
  align-items: right;
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
