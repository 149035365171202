.RGRiceDumpling-title {
    width: 95px;
    font-size: 16px;
    font-weight: bolder;
    border-bottom: 1px solid #000000;
    margin-left: 3px;
    /* margin-left: 15px; */
}

.RGRiceDumpling-terms {
    font-size: 16px;
    padding: 3px 0;
    margin-left: 3px;
    /* margin-left: 15px; */
}

.RGRiceDumpling-contactBox {
    margin-left: 3px;
    margin-bottom: 10px;
}

.RGRiceDumpling-contact {
    font-size: 16px;
    padding: 3px 0;
    /* margin-left: 15px; */
}