.bg {
    width: 70%;
    margin: auto;
    padding: 0;
}

.header {
    width: 100%;
}

.bold {
    font-weight: 700;
}

.small-words {
    font-size: 1rem;
}

.medium-words {
    font-size: 1.25rem;
}

.large-words {
    font-size: 2rem;
}

.instructions-and-qr-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.left-instructions {
    display: flex;
    flex-direction: column;
}

.instructions {
    margin-bottom: 2.5rem;
}

.right-qr *{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-qr>p {
    margin-top: 0.5rem;
    text-align: center;
}

.footer {
    background-color: #D9D9D9;
    text-align: center;
    margin-top: 6rem;
}

.footer-words {
    margin: 1rem 0;
    padding: 0.25rem;
}

@media (max-width: 540px) {
    .bg {
        width: 100%;
    }

    .instructions-and-qr-row {
        flex-direction: column-reverse;
    }

    .left-instructions {
        margin-top: 2rem;
    }
}