.riceDumpling-productDescriptionBox {
    display: flex;
    flex-direction: column; 
}

.riceDumpling-productDescription-title {
    font-size: 20px;
    font-weight: bolder;
    padding: 15px 5px 0 5px;
}

.riceDumpling-productDescription-content{
    padding: 0 10px;
}

.riceDumpling-headerBox {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-bottom: 2px solid #000000;
}

.riceDumpling-header {
    font-size: 20px;
    padding-left: 10px;
}

.riceDumpling-contentBox-1 {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
}

.riceDumpling-content-text-1 {
    font-size: 18px;
    padding-left: 10px;
}

.riceDumpling-branch {
    margin: 15px 0;
}

.riceDumpling-branchBox {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}

.riceDumpling-branchList-1 {
    width: 150px;
    display: flex;
    flex-direction: column; 
    border: 1px solid #000000;
    /* padding: 3px; */
    
}

.riceDumpling-branchList-2 {
    width: 700px;
    display: flex;
    flex-direction: column; 
    border: 1px solid #000000;
    /* padding: 3px; */
}

.riceDumpling-branchAddList {
    display: flex;
}

.riceDumpling-items-1 {
    padding: 5px 0;
    text-align: center;
}

.riceDumpling-items-2 {
    /* padding: 0 10px; */
    width: 25%;
    padding: 3px 5px;
    border: 1px solid #000000;
}

.riceDumpling-items-3 {
    /* padding: 0 10px; */
    width: 75%;
    padding: 3px 5px;
    border: 1px solid #000000;
}

.riceDumpling-usedTermsHeader {
    font-size: 16px;
    padding-left: 10px;
}

.riceDumpling-usedTermsContent {
    display: flex;
    flex-direction: column; 
}

.riceDumpling-usedTermsContent-text {
    font-size: 16px;
    padding-left: 5px;
}