.ICGSRiceDumpling-title {
    padding: 3px 0;
}

.ICGSRiceDumpling-termBox {
    margin: 5px 0;
}

.ICGSRiceDumpling-terms {
    padding: 3px;
}